export const iconFor = (type) => {
  switch (type) {
    case "mobile":
      return "mobile";
    case "adsl":
    case "fiber":
    case "broadband":
      return "modem";
    case "landline_phone":
      return "phone";
    case "switchboard":
      return "switchboard";
    default:
      return "unknown";
  }
};
